$(document).ready(function() {

  var redirectUrl = window.location.href;

  let slideTouch = false;
  let slideW = 268;
  if (navigator.maxTouchPoints > 0) {
    slideTouch = true;
  }
  const vWidth = $(window).width();
  let mxSlides = 4;
  if (vWidth < 992) {
    mxSlides = 2;
  }
  if (vWidth < 768) {
    mxSlides = 1;
    slideW = vWidth - 80;
  }

  $('.tct-slider').bxSlider({
    minSlides: 1,
    maxSlides: mxSlides,
    slideWidth: slideW,
    slideMargin: 15,
    auto: false,
    shrinkItems: true,
    touchEnabled: slideTouch,
    pager: false
  });

  $('.dropdown-toggle').on('click', function(e) {
    e.preventDefault();
    const $me = $(this);
    const $parent = $me.parent();
    if ($parent.hasClass('show')) {
      $parent.removeClass('show');
      $me.next('.dropdown-menu').removeClass('show');
    } else {
      $parent.addClass('show');
      $me.next('.dropdown-menu').addClass('show');
    }
  });

  const getQueryParameter = (param) => new URLSearchParams(document.location.search.substring(1)).get(param);
  const commentId = getQueryParameter('unapproved');
  if (commentId !== null) {
    $("html, body").animate({
      scrollTop: $(`#blog-comment-${commentId}`).offset().top
    }, 100);
  }

  $('form.login, form.register, form.forgot').on('submit', function(e) {
    e.preventDefault();
    const $me = $(this);
    const scrollPos = $(window).scrollTop();
    let secuirtyName = '';
    if ($me.attr('data-id') == 'comment-mem-form') {
      secuirtyName = 'comment';
    }

    let action = 'ajaxlogin';
    let email = $me.find('.loginEmail').val();
    let password = $me.find('.loginPassword').val();
    let username = email;
    let security = $me.find(`#security${secuirtyName}`).val();
    let name = '';
    if ($me.attr('class') === 'register') {
      action = 'ajaxregister';
      email = $me.find('.registerEmail').val();
      username = email;
      password = $me.find('.registerPassword').val();
      security = $me.find(`#signonsecurity${secuirtyName}`).val();
      name = $me.find('.registerName').val();
    }
    if ($me.attr('class') === 'forgot') {
      action = 'ajaxforgotpassword';
      email = $me.find('.forgotEmail').val();
      security = $(`#forgotsecurity${secuirtyName}`).val();
    }
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: ajax_auth_object.ajaxurl,
      data: {
        'action': action,
        'username': username,
        'password': password,
        'email': email,
        'security': security,
        'name': name
      },
      success: function(data) {
        $('p.status', $me).text(data.message);
        if (data.loggedin == true) {
          //document.location.href = redirectUrl + '#comment-form';
          document.location.reload(true)
        }
      }
    });
  });

  $('.show-reg-form').on('click', function(e) {
    e.preventDefault();
    $('.login-form-wrap').fadeOut(500, function() {
      $('.signup-form-wrap').fadeIn();
    });
  });

  $('.show-login-form').on('click', function(e) {
    e.preventDefault();
    $('.signup-form-wrap').fadeOut(500, function() {
      $('.login-form-wrap').fadeIn();
    });
  });

  $('.show-login-form-1').on('click', function(e) {
    e.preventDefault();
    $('.forgot-password-form-wrap').fadeOut(500, function() {
      $('.login-form-wrap').fadeIn();
    });
  });

  $('.show-fp-form').on('click', function(e) {
    e.preventDefault();
    $('.login-form-wrap').fadeOut(500, function() {
      $('.forgot-password-form-wrap').fadeIn();
    });
  });

  $('.navbar-toggler').on('click', function(e) {
    e.preventDefault();
    const $me = $(this);
    const target = $me.attr('data-target');
    $me.toggleClass('open');
    $('body').toggleClass('menu-on');
    $(target).addClass('nav-bg');
    $(target).slideToggle(100, function() {
      $(target).toggleClass('show');
    });
  });

  if (navigator.maxTouchPoints > 0) {
    $('.tags li').on('click', function() {
      const $me = $(this);
      window.location.href = $me.data('slug');
    });
  }
});

